<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                {{$t('Continuidad de Negocio')}}
            </template>
            <template v-slot:subtitle>
                <div v-if="formData.datos.tipocliente.code==2">{{$t('solicitada')}}</div>
                <div v-else>{{$t('NO APLICA')}}</div>
            </template>
            <template v-slot:content>
                <div class="p-fluid p-formgrid p-grid" v-if="formData.datos.tipocliente.code==2">
                    <div class="p-field p-col-12 p-md-12">
						<DataTable :value="continuidad" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
								:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
							<Column :header="$t('Descripción')">
								<template #body="slotProps">
									<div v-if="i18n.locale() == 'es'">{{slotProps.data.titulo.nombre}}</div>
									<div v-if="i18n.locale() == 'en'">{{slotProps.data.titulo.name}}</div>
								</template>
							</Column>
							<Column :header="$t('Valor')">
								<template #body="slotProps">
									{{slotProps.data.valor}}
								</template>
							</Column>
							<Column>
								<template #body="slotProps">
									<Button :disabled ="$store.state.loading" icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="Delete(slotProps.data)"/>
								</template>
							</Column>
						</DataTable>
                        <small v-show="validationErrors.continuidad && submitted" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-4 p-md-2" v-if="!Dialog">
						<Button :disabled ="$store.state.loading" :label="$t('Agregar')" icon="pi pi-plus-circle" class="p-button-secondary"  @click="Dialog = true"/>
					</div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="p-grid p-nogutter p-justify-between">
                    <Button :disabled ="$store.state.loading" :label="$t('Anterior')" @click="prevPage()" icon="pi pi-angle-left" />
                    <Button :disabled ="$store.state.loading" :label="$t('Siguiente')" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
        <Dialog v-model:visible="Dialog" :style="{width: size}" :header="$t('Agregar')" :modal="true" :maximizable="true"> 
            <br>
            <div class="confirmation-content">
                <div class="p-fluid p-formgrid p-grid">
					<div class="p-field p-col-12">
						<label>{{$t('Descripción')}}</label>
						<Dropdown id="state" v-model="cont.titulo" :options="opciones" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')"></Dropdown>
                        <small v-show="validationErrors.titulo" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-6">
						<label>{{$t('Valor')}}</label>
                        <InputNumber id="horizontal" v-model="cont.valor" showButtons buttonLayout="horizontal" 
                            decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus" 
                            decrementButtonIcon="pi pi-minus" min="0" mode="currency" :currency="$store.state.currency" />
                        <small v-show="validationErrors.valor" class="p-error">{{$t('required')}}</small>
					</div>
                </div>
            </div>
            <template #footer>
                <Button :disabled ="$store.state.loading" :label="$t('Agregar')" icon="pi pi-plus-circle" class="p-button-secondary" @click="Agregar" />
                <Button :disabled ="$store.state.loading" :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="Dialog = false"/>
            </template>
        </Dialog>
    </div>
</template>

<script>
import API from "@/service/API";
const Consulta = new API('Cotizacion','Marine');
import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";

export default {
    props: {
        formData: Object
    },
    data () {
        return {
			submitted: false,
            validationErrors: {},
			opciones: [],
			Dialog: false,
			continuidad: [],
			cont: {
				titulo: null,
				valor: 0,
			},
            size: '40vw',
			url: null,
			contador: 0,
        }
    },
	created() {
        this.opciones = [];		
		this.contador = 0;
		this.url = url;
        if (this.$store.state.mobile){this.size = '80vw';}
		this.i18n = useI18nPlugin();
		Consulta.Ini().then(result => {
			this.opciones = result.consult.opcion2;
		});
	},
    methods: {
		Agregar() {
            if (this.validateForm2()) {
				this.continuidad.push(this.cont);
				this.Dialog = false;
                this.cont = {
                    titulo: null,
                    valor: 0,
                };
            }
		},
		Delete(selec) {
			this.continuidad = this.continuidad.filter(val => val !== selec);
		},
        nextPage() {
            this.submitted = true;
            if (this.validateForm() ) {
                this.$emit('next-page', {formData: {continuidad: this.continuidad}, pageIndex: 3});
            }
        },
        prevPage() {
            this.$emit('prev-page', {pageIndex: 3});
        },
        validateForm() {
            if (this.continuidad.length==0 && this.formData.datos.tipocliente.code==2)
               this.validationErrors['continuidad'] = true;
            else
               delete this.validationErrors['continuidad'];
            return !Object.keys(this.validationErrors).length;
        },
        validateForm2() {
            if (this.cont.titulo==null)
               this.validationErrors['titulo'] = true;
            else
               delete this.validationErrors['titulo'];
            if (!this.cont.valor)
               this.validationErrors['valor'] = true;
            else
               delete this.validationErrors['valor'];
            return !Object.keys(this.validationErrors).length;
        },
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
    }
}
</script>